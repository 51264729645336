import React from "../../_snowpack/pkg/react.js";
import {useParams} from "../../_snowpack/pkg/react-router.js";
import EmailListController from "../components/controllers/EmailListController.js";
import EmailViewController from "../components/controllers/EmailViewController.js";
import MainAsideLayout from "../layout/MainAsideLayout.js";
const EmailDetailPage = () => {
  const {id} = useParams();
  return /* @__PURE__ */ React.createElement(MainAsideLayout, null, /* @__PURE__ */ React.createElement(MainAsideLayout.Main, null, /* @__PURE__ */ React.createElement("div", {
    className: "max-w-3xl mx-auto"
  }, /* @__PURE__ */ React.createElement(EmailViewController, {
    id
  }))), /* @__PURE__ */ React.createElement(MainAsideLayout.Aside, null, /* @__PURE__ */ React.createElement(EmailListController, null)));
};
export default EmailDetailPage;
