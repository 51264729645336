import React from "../../../_snowpack/pkg/react.js";
import gql from "../../../_snowpack/pkg/graphql-tag.js";
import {useEmailListQuery} from "../../generated/graphql.js";
import EmailList from "../EmailList.js";
const EMAIL_LIST_QUERY = gql`
  query EmailList {
    emails {
      id
      subject
      receivedAt
      read
      sentBy {
        id
        name
      }
    }
  }
`;
const EmailListController = () => {
  const {data, loading, error} = useEmailListQuery();
  const emails = data?.emails.map((e) => ({
    ...e,
    href: `/emails/${e.id}`,
    preview: "Doloremque dolorem maiores assumenda dolorem facilis. Velit vel in a rerum natus facere. Enim rerum eaque qui facilis. Numquam laudantium sed id dolores omnis in. Eos reiciendis deserunt maiores et accusamus quod dolor."
  }));
  if (emails)
    return /* @__PURE__ */ React.createElement(EmailList, {
      emails
    });
  if (error)
    return /* @__PURE__ */ React.createElement("p", null, "Error!");
  if (loading)
    return /* @__PURE__ */ React.createElement("p", null, "Loading...");
  return null;
};
export default EmailListController;
