import React from "../../_snowpack/pkg/react.js";
import {Link, useLocation} from "../../_snowpack/pkg/react-router-dom.js";
import {
  CloudUploadIcon,
  FilterIcon,
  FlagIcon,
  InboxIcon,
  TrashIcon,
  UsersIcon
} from "../../_snowpack/pkg/@heroicons/react/outline.js";
import classNames from "../util/classNames.js";
const navigation = [
  {name: "Inbox", href: "/emails", icon: InboxIcon},
  {name: "Accounts", href: "/accounts", icon: UsersIcon},
  {name: "Spam", href: "/spam", icon: FlagIcon},
  {name: "Sent", href: "/sent", icon: CloudUploadIcon},
  {name: "Filters", href: "/filters", icon: FilterIcon},
  {name: "Trash", href: "/trash", icon: TrashIcon}
];
const Navbar = () => {
  const {pathname} = useLocation();
  return /* @__PURE__ */ React.createElement("div", {
    className: "flex flex-col flex-1 h-0 bg-gray-100 border-r border-gray-200"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "flex flex-col flex-1 pt-5 pb-4 overflow-y-auto"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "flex items-center flex-shrink-0 px-4"
  }, /* @__PURE__ */ React.createElement("img", {
    className: "w-auto h-8",
    src: "https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-900-text.svg",
    alt: "Workflow"
  })), /* @__PURE__ */ React.createElement("nav", {
    className: "flex-1 mt-5",
    "aria-label": "Sidebar"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "px-2 space-y-1"
  }, navigation.map((i) => ({...i, current: pathname.startsWith(i.href)})).map((item) => /* @__PURE__ */ React.createElement(Link, {
    key: item.name,
    to: item.href,
    className: classNames(item.current ? "bg-gray-200 text-gray-900" : "text-gray-600 hover:bg-gray-50 hover:text-gray-900", "group flex items-center px-2 py-2 text-base lg:text-sm font-medium rounded-md")
  }, /* @__PURE__ */ React.createElement(item.icon, {
    className: classNames(item.current ? "text-gray-500" : "text-gray-400 group-hover:text-gray-500", "mr-4 lg:mr-3 h-6 w-6"),
    "aria-hidden": "true"
  }), item.name))))), /* @__PURE__ */ React.createElement("div", {
    className: "flex flex-shrink-0 p-4 border-t border-gray-200"
  }, /* @__PURE__ */ React.createElement("a", {
    href: "#",
    className: "group flex-shrink-0 block w-full"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "flex items-center"
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("img", {
    className: "h-9 w-9 inline-block rounded-full",
    src: "https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80",
    alt: ""
  })), /* @__PURE__ */ React.createElement("div", {
    className: "ml-3"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "group-hover:text-gray-900 text-sm font-medium text-gray-700"
  }, "Whitney Francis"), /* @__PURE__ */ React.createElement("p", {
    className: "group-hover:text-gray-700 text-xs font-medium text-gray-500"
  }, "View profile"))))));
};
export default Navbar;
