import React from "../../_snowpack/pkg/react.js";
import {Link} from "../../_snowpack/pkg/react-router-dom.js";
import classNames from "../util/classNames.js";
const EmailListItem = ({
  email,
  active = false
}) => /* @__PURE__ */ React.createElement("div", {
  className: classNames("hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 relative px-6 py-5 bg-white", active && "border-l-2 border-blue-600 ", email.read && !active && "bg-gray-100")
}, /* @__PURE__ */ React.createElement("div", {
  className: "flex justify-between space-x-3"
}, /* @__PURE__ */ React.createElement("div", {
  className: "flex-1 min-w-0"
}, /* @__PURE__ */ React.createElement(Link, {
  to: email.href,
  className: "focus:outline-none block"
}, /* @__PURE__ */ React.createElement("span", {
  className: "absolute inset-0",
  "aria-hidden": "true"
}), /* @__PURE__ */ React.createElement("p", {
  className: classNames("text-sm font-medium truncate", !active && "text-gray-900", active && "text-blue-700")
}, email.sentBy.name), /* @__PURE__ */ React.createElement("p", {
  className: "text-sm text-gray-500 truncate"
}, email.subject))), /* @__PURE__ */ React.createElement("time", {
  dateTime: email.receivedAt,
  className: "whitespace-nowrap flex-shrink-0 text-sm text-gray-500"
}, "1d ago")), /* @__PURE__ */ React.createElement("div", {
  className: "mt-1"
}, /* @__PURE__ */ React.createElement("p", {
  className: "line-clamp-2 text-sm text-gray-600"
}, email.preview)));
export default EmailListItem;
