import {gql} from "../../_snowpack/pkg/@apollo/client.js";
import * as Apollo from "../../_snowpack/pkg/@apollo/client.js";
const defaultOptions = {};
export const EmailListDocument = gql`
    query EmailList {
  emails {
    id
    subject
    receivedAt
    read
    sentBy {
      id
      name
    }
  }
}
    `;
export function useEmailListQuery(baseOptions) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery(EmailListDocument, options);
}
export function useEmailListLazyQuery(baseOptions) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery(EmailListDocument, options);
}
export const EmailViewDocument = gql`
    query EmailView($id: ID!) {
  email(id: $id) {
    id
    content
  }
}
    `;
export function useEmailViewQuery(baseOptions) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery(EmailViewDocument, options);
}
export function useEmailViewLazyQuery(baseOptions) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery(EmailViewDocument, options);
}
