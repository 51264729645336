import React, {Fragment} from "../../_snowpack/pkg/react.js";
import {Dialog, Transition} from "../../_snowpack/pkg/@headlessui/react.js";
import {XIcon} from "../../_snowpack/pkg/@heroicons/react/outline.js";
const Slideover = ({children, open, setOpen}) => /* @__PURE__ */ React.createElement(Transition.Root, {
  show: open,
  as: Fragment
}, /* @__PURE__ */ React.createElement(Dialog, {
  as: "div",
  static: true,
  className: "lg:hidden fixed inset-0 z-40 flex",
  open,
  onClose: setOpen
}, /* @__PURE__ */ React.createElement(Transition.Child, {
  as: Fragment,
  enter: "transition-opacity ease-linear duration-300",
  enterFrom: "opacity-0",
  enterTo: "opacity-100",
  leave: "transition-opacity ease-linear duration-300",
  leaveFrom: "opacity-100",
  leaveTo: "opacity-0"
}, /* @__PURE__ */ React.createElement(Dialog.Overlay, {
  className: "fixed inset-0 bg-gray-600 bg-opacity-75"
})), /* @__PURE__ */ React.createElement(Transition.Child, {
  as: Fragment,
  enter: "transition ease-in-out duration-300 transform",
  enterFrom: "-translate-x-full",
  enterTo: "translate-x-0",
  leave: "transition ease-in-out duration-300 transform",
  leaveFrom: "translate-x-0",
  leaveTo: "-translate-x-full"
}, /* @__PURE__ */ React.createElement("div", {
  className: "focus:outline-none relative flex flex-col flex-1 w-full max-w-xs bg-white"
}, /* @__PURE__ */ React.createElement(Transition.Child, {
  as: Fragment,
  enter: "ease-in-out duration-300",
  enterFrom: "opacity-0",
  enterTo: "opacity-100",
  leave: "ease-in-out duration-300",
  leaveFrom: "opacity-100",
  leaveTo: "opacity-0"
}, /* @__PURE__ */ React.createElement("div", {
  className: "absolute top-0 right-0 pt-2 -mr-12"
}, /* @__PURE__ */ React.createElement("button", {
  type: "button",
  className: "focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white flex items-center justify-center w-10 h-10 ml-1 rounded-full",
  onClick: () => setOpen(false)
}, /* @__PURE__ */ React.createElement("span", {
  className: "sr-only"
}, "Close sidebar"), /* @__PURE__ */ React.createElement(XIcon, {
  className: "w-6 h-6 text-white",
  "aria-hidden": "true"
})))), children)), /* @__PURE__ */ React.createElement("div", {
  className: "w-14 flex-shrink-0",
  "aria-hidden": "true"
})));
export default Slideover;
