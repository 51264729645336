import React, {useState} from "../../_snowpack/pkg/react.js";
import MobileHeader from "../components/MobileHeader.js";
import Navbar from "../components/Navbar.js";
import Slideover from "../components/Slideover.js";
const AppLayout = ({children}) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return /* @__PURE__ */ React.createElement("div", {
    className: "flex h-screen overflow-hidden bg-white"
  }, /* @__PURE__ */ React.createElement(Slideover, {
    open: sidebarOpen,
    setOpen: setSidebarOpen
  }, /* @__PURE__ */ React.createElement(Navbar, null)), /* @__PURE__ */ React.createElement("div", {
    className: "lg:flex lg:flex-shrink-0 hidden"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "flex flex-col w-64"
  }, /* @__PURE__ */ React.createElement(Navbar, null))), /* @__PURE__ */ React.createElement("div", {
    className: "flex flex-col flex-1 min-w-0 overflow-hidden"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "lg:hidden"
  }, /* @__PURE__ */ React.createElement(MobileHeader, {
    onToggleMenu: () => setSidebarOpen((open) => !open)
  })), /* @__PURE__ */ React.createElement("div", {
    className: "relative z-0 flex flex-1 overflow-hidden"
  }, children)));
};
export default AppLayout;
