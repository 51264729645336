import React from "../_snowpack/pkg/react.js";
import {BrowserRouter as Router} from "../_snowpack/pkg/react-router-dom.js";
import {ApolloProvider} from "../_snowpack/pkg/@apollo/client.js";
import Routes from "./Routes.js";
import AppLayout from "./layout/AppLayout.js";
import {createClient} from "./graphql/client.js";
import {startServer} from "./mirage.js";
startServer();
const client = createClient();
const App = () => /* @__PURE__ */ React.createElement(ApolloProvider, {
  client
}, /* @__PURE__ */ React.createElement(Router, null, /* @__PURE__ */ React.createElement(AppLayout, null, /* @__PURE__ */ React.createElement(Routes, null))));
export default App;
