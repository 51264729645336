import React from "../_snowpack/pkg/react.js";
import {Route, Switch} from "../_snowpack/pkg/react-router-dom.js";
import EmailDetailPage from "./pages/EmailDetailPage.js";
import EmailListPage from "./pages/EmailListPage.js";
const Routes = () => /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(Route, {
  path: "/emails/:id"
}, /* @__PURE__ */ React.createElement(EmailDetailPage, null)), /* @__PURE__ */ React.createElement(Route, {
  path: "/emails"
}, /* @__PURE__ */ React.createElement(EmailListPage, null)), /* @__PURE__ */ React.createElement(Route, {
  path: "*"
}, /* @__PURE__ */ React.createElement("p", {
  className: "text-red-500"
}, "Not found")));
export default Routes;
