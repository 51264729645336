import React from "../../_snowpack/pkg/react.js";
import {useLocation} from "../../_snowpack/pkg/react-router.js";
import EmailListItem from "./EmailListItem.js";
const EmailList = ({emails}) => {
  const {pathname} = useLocation();
  return /* @__PURE__ */ React.createElement("nav", {
    "aria-label": "Email list",
    className: "flex-1 min-h-0 overflow-y-auto"
  }, /* @__PURE__ */ React.createElement("ul", {
    className: "border-b border-gray-200 divide-y divide-gray-200"
  }, emails.map((email) => /* @__PURE__ */ React.createElement("li", {
    key: email.id
  }, /* @__PURE__ */ React.createElement(EmailListItem, {
    email,
    active: email.href === pathname
  })))));
};
export default EmailList;
