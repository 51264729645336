import React from "../../_snowpack/pkg/react.js";
import {MenuIcon} from "../../_snowpack/pkg/@heroicons/react/outline.js";
const MobileHeader = ({onToggleMenu}) => /* @__PURE__ */ React.createElement("div", {
  className: "flex items-center justify-between bg-gray-50 border-b border-gray-200 px-4 py-1.5"
}, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("img", {
  className: "w-auto h-8",
  src: "https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg",
  alt: "Workflow"
})), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("button", {
  type: "button",
  className: "hover:text-gray-900 inline-flex items-center justify-center w-12 h-12 -mr-3 text-gray-500 rounded-md",
  onClick: () => onToggleMenu()
}, /* @__PURE__ */ React.createElement("span", {
  className: "sr-only"
}, "Open sidebar"), /* @__PURE__ */ React.createElement(MenuIcon, {
  className: "w-6 h-6",
  "aria-hidden": "true"
}))));
export default MobileHeader;
