import React from "../../_snowpack/pkg/react.js";
const MainAsideLayoutContainer = ({children}) => /* @__PURE__ */ React.createElement("div", {
  className: "relative z-0 flex flex-1 overflow-hidden"
}, children);
const MainAsideLayout_Main = ({children}) => /* @__PURE__ */ React.createElement("main", {
  className: "focus:outline-none xl:order-last relative z-0 flex-1 overflow-y-auto"
}, children);
const MainAsideLayout_Aside = ({children}) => /* @__PURE__ */ React.createElement("aside", {
  className: "xl:order-first xl:flex xl:flex-col w-96 relative flex-shrink-0 hidden border-r border-gray-200"
}, children);
const MainAsideLayout = MainAsideLayoutContainer;
MainAsideLayout.Main = MainAsideLayout_Main;
MainAsideLayout.Aside = MainAsideLayout_Aside;
export default MainAsideLayout;
