import React from "../../../_snowpack/pkg/react.js";
import {gql} from "../../../_snowpack/pkg/@apollo/client.js";
import {useEmailViewQuery} from "../../generated/graphql.js";
import EmailView from "../EmailView.js";
const EMAIL_VIEW_QUERY = gql`
  query EmailView($id: ID!) {
    email(id: $id) {
      id
      content
    }
  }
`;
const EmailViewController = ({id}) => {
  const {data, loading, error} = useEmailViewQuery({variables: {id}});
  if (data)
    return /* @__PURE__ */ React.createElement(EmailView, {
      raw: data.email.content
    });
  if (error)
    return /* @__PURE__ */ React.createElement("p", null, "Error!");
  if (loading)
    return /* @__PURE__ */ React.createElement("p", null, "Loading...");
  return null;
};
export default EmailViewController;
